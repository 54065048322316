.disabled {
    position: relative;
    border-radius: 8px;
    background: linear-gradient(#e0e0e0, #e0e0e0), #106967;
    border: 2px #909090;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 12px;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #909090;
    font-family: Poppins;
}

.Back {
    position: relative;
    border: 2px solid #ffffff;
    border-radius: 8px 0px 0px 8px;
    background-color: #e0e0e0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
    box-sizing: border-box;
    cursor: pointer;
    } 

.Back:hover {
    cursor: pointer;
    color: white;
    box-shadow: inset 0px 0px 6px 1px rgba(0, 0, 0, 0.350);
    transition: 0.3s;
    background-color: #4fbc9c;
    /* border: 2px solid #111;  */
}

.Back:active {
    cursor: pointer;
    color: white;
    background-color: #3a3a3a;
    border: 2px solid #111; 
    transition: 0.3s;
}

.Alt {
    display: flex;
    height: 36px;
    padding: 8px;
    align-items: center;
    border: 2px solid #515151;;
    border-radius: 8px;
    gap: 8px;
    background-color: #515151;
    width: 100%;
    justify-content: space-between;
    color: #FBFBFB;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Alt:hover {
    cursor: pointer;
    border-radius: 8px;
    background-color: #5a5a5a;
    border: 2px solid #111; 
    transition: 0.3s;
}

.Alt:Active {
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
    background-color: #111;
    border: 2px solid #111;
    transition: 0.3s;
}

.Alt-Ghost {
    position: relative;
    border-radius: 8px;
    border: 2px solid #515151;
    box-sizing: border-box;
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #515151;
    font-family: Poppins;
}

.Alt-Ghost:hover {
    cursor: pointer;
    border-radius: 8px;
    color: white;
    background-color: #5a5a5a;
    border: 2px solid #111;
    transition: 0.3s; 
}

.Alt-Ghost:Active {
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
    background-color: #111;
    border: 2px solid #111;
    transition: 0.3s;
}

.Round {
    display: flex;
    height: auto;
    padding: 0px;
    align-items: center;
    border: none;
    border-radius: 999px;
    background: #4fbc9c;
    border: 2px solid #42a084;
    width: 100%;
    justify-content: space-between;
    color: #FBFBFB;
}

.Round:hover {
    cursor: pointer;
    border-radius: 999px;
    border: 2px solid #163235;
    background: #106967;
    transition: 0.3s;    
}

.Round:Active {
    cursor: pointer;
    border-radius: 999px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
    background-color: #163235;
    border: 2px solid #163235;
    transition: 0.3s;
}

.Default {
    display: flex;
    width: 100%;
    height: 36px;
    padding: 8px;
    align-items: center;
    border: none;
    border-radius: 8px;
    gap: 8px;
    background: #106967;
    border: 2px solid #106967;
    width: 100%;
    justify-content: space-between;
    color: #FBFBFB;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Default:hover {
    cursor: pointer;
    border-radius: 8px;
    border: 2px solid #163235;
    background: #106967;
    transition: 0.3s;    
}

.Default:Active {
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
    background-color: #163235;
    border: 2px solid #163235;
    transition: 0.3s;
}

.transp {
    background-color: transparent;
}
.Ghost {
    display: flex;
    width: 100%;
    height: 36px;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 2px solid #4FBC9C;
    font-family: Poppins;
    font-weight: 700;
    color: #163235;
    
}

.Ghost:hover {
    cursor: pointer;
    border-radius: 8px;
    color: white;
    border: 2px solid #163235;
    background: #106967; 
    transition: 0.3s;   
}

.Ghost:Active {
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
    background-color: #163235;
    border: 2px solid #163235;
    transition: 0.3s;
}
.icon {
    position: relative;
    border-radius: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1px;
    box-sizing: border-box;
    scale: 80%;
    }

.StatusLabel {
    flex: 1;
    width: 100%;
    position: relative;
    line-height: 150%;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 11px;
    color: #fbfbfb;
    font-family: poppins;
    }
   
.Critical {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 36px;
    background-color: #c40000;
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px 8px 3px;
    box-sizing: border-box;
    text-align: center;
    font-size: 11px;
    color: #fbfbfb;
    }

.Active {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 36px;
    background-color: #28a745;
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px 8px 3px;
    box-sizing: border-box;
    text-align: center;
    font-size: 11px;
    color: #fbfbfb;
    }

.Moderate {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 36px;
    background-color: #f5b700;
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px 8px 3px;;
    box-sizing: border-box;
    text-align: center;
    font-size: 11px;
    color: #fbfbfb;
}

.Complete {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 36px;
    background-color: #8e8e8e;
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px 8px 3px;;
    box-sizing: border-box;
    text-align: center;
    font-size: 11px;
    color: #fbfbfb;
    }

.Canceled {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 36px;
    background-color: #5a5a5a;
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px 8px 3px;;
    box-sizing: border-box;
    text-align: center;
    font-size: 11px;
    color: #fbfbfb;
    }

.Draft {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 36px;
    background-color: #007c89;
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px 8px 3px;;
    box-sizing: border-box;
    text-align: center;
    font-size: 11px;
    color: #fbfbfb;
    } 

a:link { 
    text-decoration: none; 
    } 
a:visited { 
    text-decoration: none; 
} 
a:hover { 
    text-decoration: none; 
} 
a:active { 
    text-decoration: none; 
}