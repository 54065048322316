.grid-case-details {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    margin-top: 12px;
    height: calc(100vh - 190px);
    overflow-y: scroll;
}
.hidden {
    display: none;
}

.visible {
    display: flex;
}

.nullValue {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.list-patient-details {
    position: relative;
    border-radius: 8px;
    background-color: rgba(245, 245, 245, 0.75);
    border: 2px solid #fbfbfb;
    box-sizing: border-box;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #163235;
    font-family: Poppins;
}

.taskGRP {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #fbfbfb;
    border: 2px solid #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px;
    text-align: left;
    font-size: 10px;
    color: #333;
    font-family: Poppins;
}

.summary-content-accord {
    width: 100%;
    position: relative;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 8px 8px;
    border: 2px solid #4fbc9c;
    border-top-style: none;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    box-sizing: border-box;
    text-align: left;
    font-size: 10px;
    color: #333;
    font-family: Poppins;
}

.summary-collapse{
    border: 2px solid #cdcdcd;
    border-radius: 8px;
}

.summary-expand {
    border-radius: 8px 8px 0px 0px;
    border: 2px solid #4fbc9c;
    border-bottom-style: none;
}
.summary-label-name {
    width: 100%;
    position: relative;
    font-size: 10px;
    font-size: 12px;
    font-weight: 600;
    font-family: Poppins;
    color: #333;
    text-align: left;
    display: inline-block;
}

.title-time {
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    text-align: left;
    font-size: 10px;
    color: #333;
    font-family: Poppins;
}

.summary-head {
    width: 100%;
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 12px;
    box-sizing: border-box;
    gap: 4px;
    text-align: left;
    font-size: 10px;
    color: #333;
    font-family: Poppins;
}

.summaryGRP {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #fbfbfb;
    border: 2px solid #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px;
    text-align: left;
    font-size: 10px;
    color: #333;
    font-family: Poppins;
}

.esca-mapped {
    width: 100%;
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.collapse {
    border-radius: 8px;

}

.expand {
    border-radius: 8px 8px 0px 0px;

}
.esc-content {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 12px;
    color: #333;
    font-family: Poppins;
}

.lbel {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 0px 8px;
    box-sizing: border-box;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: #163235;
    font-family: Poppins;
}
.suggestion {
    width: 100%;
    position: relative;
    border-radius: 0px 0px 8px 8px;
    background-color: #fff;
    border: 1px solid #163235;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 12px;
    color: #000;
    font-family: Poppins;
    padding: 12px;
}

.right-esca {
    position: relative;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
    box-sizing: border-box;
}

.head-esca {
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 24px 0px 0px;
    box-sizing: border-box;
    gap: 8px;
    text-align: left;
    font-size: 14px;
    color: #fbfbfb;
    font-family: Poppins;
}

.Label-esca {
    width: 100%;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    font-family: Poppins;
    color: #fbfbfb;
    text-align: left;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;    
}

.date-esca {
    position: relative;
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 10px;
    color: #ccc;
    font-family: Poppins;
    gap: 12px;
}

.left-esca {
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    text-align: left;
    font-size: 14px;
    color: #fbfbfb;
    font-family: Poppins;
}

.healthcase-head {
    width: 100%;
    position: relative;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06), -2px -2px 9px rgba(0, 0, 0, 0.06);
    background-color: #163235;
    border: 2px solid #163235;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    gap: 4px;
    text-align: left;
    font-size: 14px;
    color: #fbfbfb;
    font-family: Poppins;
    }

.escalations {
    width: 100%;
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
    color: #fbfbfb;
    font-family: Poppins;
}

.main-head {
    width: 100%;
    position: relative;
    box-shadow: 4px 4px 32px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    background: linear-gradient(-17.07deg, #e6e6e6, #f3f3f3 51.26%, #eaeaea);
    border: 3px solid #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin: 0px 0px 12px 0px;
    text-align: left;
    font-size: 16px;
    color: #163235;
    font-family: Poppins;
    }

.title {
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    color: #163235;
    font-family: Poppins;
}
.blockHead {
    width: 100%;
    position: relative;
    border-radius: 8px 8px 0px 0px;
    background-color: #4fbc9c;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    color: #fbfbfb;
    font-family: Poppins;
}

.blockBody {
    width: 100%;
    position: relative;
    border-radius: 0px 0px 8px 8px;
    background-color: #f5f5f5;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 4px 8px;
    text-align: left;
    font-size: 12px;
    color: #08191c;
    font-family: Poppins;
}

.blocks {
    width: 100%;
    position: relative;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 12px;
    color: #fbfbfb;
    font-family: Poppins;
}

.info {
    width: 100%;
    position: relative;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06), -2px -2px 9px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    background: linear-gradient(93.84deg, #fff, #f8f8f8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    box-sizing: border-box;
    gap: 8px;
    text-align: left;
    font-size: 12px;
    color: #fbfbfb;
    font-family: Poppins;
}

.list-documents {
    width: 100%;
    position: relative;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06), -2px -2px 9px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    background: linear-gradient(93.84deg, #f6f6f6, #e6e6e6 68.9%, #f8f8f8);
    border: 2px solid #fbfbfb;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    gap: 4px;
    text-align: center;
    font-size: 14px;
    color: #163235;
    font-family: Poppins;
    }
.conversation {
    width: 20%;
    position: relative;
    border-radius: 8px;
    background-color: rgba(245, 245, 245, 0.75);
    border: 2px solid #fbfbfb;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #163235;
    font-family: Poppins;

    }

.summary {
    width: 30%;
    position: relative;
    border-radius: 8px;
    background-color: rgba(245, 245, 245, 0.75);
    border: 2px solid #fbfbfb;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #163235;
    font-family: Poppins;

    }

.escalationgrp {
    width: 100%;
    flex: 1;
    position: relative;
    border-radius: 8px;
    background-color: rgba(245, 245, 245, 0.75);
    border: 2px solid #fbfbfb;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #163235;
    font-family: Poppins;

}

.patient-details {
    width: 25%;
    position: relative;
    border-radius: 8px;
    background-color: rgba(245, 245, 245, 0.75);
    border: 2px solid #fbfbfb;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #163235;
    font-family: Poppins;
}

.ListView {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
}

.nameAgeGRP {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
}

.ageGender {
    font-size: 16px;
    display: flex;
    width: 100%;
    font-family: Poppins;
    color: #08191C;
    justify-content: flex-end;
    flex-direction: row;
}

.caseListCard {
    display: flex;
    width: 20%;
    gap: 12px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: scroll;
}

.CaseEmpty {
    color:#08191C;
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content:center;
    flex-direction: row;
    width: 100%;
    position: relative;
}

.CaseList {
    width: 100%;
    position: relative;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06), -2px -2px 9px rgba(0, 0, 0, 0.06);
    border: none;
    border-radius: 8px;
    border: 2px solid #f8f8f8;
    background: linear-gradient(93.84deg, #fff, #f8f8f8);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    box-sizing: border-box;
    gap: 4px;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
    color: #007c89;
    font-family: Poppins;
}

.CaseList:hover {
    cursor: pointer;
    border: 2px solid #007c89;
    background: #f3f3f3;
    transition: 0.3s;   
}

.CaseList.active {
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.75), -2px -2px 9px rgba(0, 0, 0, 0.06);
    background: linear-gradient(93.84deg, #fff, #f8f8f8);
    border-left: 4px solid #4fbc9c;
    box-sizing: border-box;
}

.list-patient-name {
    width: 100%;
    position: relative;
    font-size: 16px;
    display: inline-block;
    font-family: Poppins;
    color: #007c89;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }

.lastUpdated {
    color: var(--Teal-dark, #08191C);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}
    
.Severity {
    width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    text-align: center;
    font-size: 11px;
    color: #fbfbfb;
    }
