/* Modal render */
/* Add this to your CSS file */
.info-head {
    position: relative;
    font-size: 20px;
    font-weight: 600;
    font-family: Poppins;
    color: #167d7f;
    text-align: left;
    display: flex;
    align-items: center;
}
.info-content {
    position: relative;
    font-size: 14px;
    font-family: Poppins;
    color: #333;
    text-align: left;
    display: inline-block;
}

.subClose {
    color: #08191c;
}

.subClose:hover {
    cursor: pointer;
    color: #4fbc9c;
}

.modal-container {
    height: calc(90vh - 250px);
    display: block;
    left: -338px;
    backdrop-filter: blur(12px);
    border-radius: 8px;
    background-color: rgba(230, 230, 230, 0.75);
    border: 2px solid #fbfbfb;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    gap: 8px;
    overflow-y: scroll;
    }

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Overlay background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it is above other content */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 550px;
    width: 90%;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
}

/* ________________ */
.new-case-suggestion-search {
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}
.new-case-suggestion-search:hover {
    background-color: #98d7c2;
    color: white;
    transition: 0.1s;
}
.NewCasesearchBar {
    position: relative;
    border-radius: 8px;
    background-color: rgba(251, 251, 251, 0.48);
    border: 1px solid #b3b3b3;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 48px 12px 12px;
    gap: 12px;
    text-align: center;
    font-size: 18px;
    color: #909090;
    font-family: Poppins;
}

.phone-inputIcon {
    position: absolute;
    right: 12px;
    display: flex;
    align-items: center;
    color: gray;
    top: 10px;
}
.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.patientInputLine {
    width: 774px;
    position: relative;
    border: none;
    border-radius: 0px 8px 8px 0px;
    background-color: #fbfbfb;
    height: 48px;
    display: block;
    left: 0px;
    padding: 12px;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
}


.PatientInputLineContainer {
    width: 100%;
    position: relative;
    border: none;
    appearance: none;
    border-radius: 8px;
    /* background-color: #fbfbfb; */
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
}

.patientInputLine:hover {
    border: 1px solid #4fbc9c;
    display: block;
    outline: none;
    border-radius: 0px 8px 8px 0px;
}

.patientInputLine:focus {
    display: block;
    outline: none;
    border-radius: 0px 8px 8px 0px;
    border-left: 4px solid #4fbc9c;
    box-sizing: border-box;
}

.patient-phone {
    width: 100%;
}

.newcase-container {
    width: 100%;
    height: calc(100vh - 250px);
    display: block;
    left: -338px;
    backdrop-filter: blur(12px);
    border-radius: 8px;
    background-color: rgba(230, 230, 230, 0.75);
    border: 2px solid #fbfbfb;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    gap: 8px;
    overflow-y: scroll;
    }

.newcase-care-drop-body {
    width: 100%;
    position: relative;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06), -2px -2px 9px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 8px 8px;
    background: linear-gradient(93.84deg, #f6f6f6, #e6e6e6 68.9%, #f8f8f8);
    border: 2px solid #fbfbfb;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    text-align: left;
    font-size: 18px;
    color: #515151;
    font-family: Poppins;
    gap: 4px;
    }

.newcase-healthcase-head {
    width: 100%;
    position: relative;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06), -2px -2px 9px rgba(0, 0, 0, 0.06);
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(95.22deg, #cecdcd, #f5f5f5 44.4%, #c4c4c4);
    border: 2px solid #fbfbfb;
    box-sizing: border-box;
    height: 58px;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    font-size: 26px;
    font-weight: 700;
    color: #515151;
    font-family: Poppins;
    }

.newcase-carecard {
    width: 100%;
    position: relative;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06), -2px -2px 9px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    background: linear-gradient(93.84deg, #f6f6f6, #e6e6e6 68.9%, #f8f8f8);
    border: 2px solid #fbfbfb;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 26px;
    color: #515151;
    font-family: Poppins;
}
.Provider-blockBody {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #f5f5f5;
    border: 2px solid #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    text-align: left;
    font-size: 12px;
    color: #08191c;
    font-family: Poppins;
}

.details {
    height: calc(100vh - 240px); /* Adjusting for a header of 50px height */
    flex: 1;
    width: 100%;
    position: relative;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #163235;
    font-family: Poppins, sans-serif;
    overflow-y: scroll;
}

.AddCaseView {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    height: calc(100vh - 245px);
}