/* Header.css */

.suggestions {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 12px;
    width: 100%;
    top: 40px;
    left: 0px;
    padding: 8px;
    max-height: 200px;
    font-family: Poppins;
    font-size: 12px;
    overflow-y: auto;
    z-index: 1000;
}

.suggestion-search {
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.suggestion-search:hover {
    background-color: #4FBC9C;
    color: white;
}

/* ________________________________ */
.containers {
    position: relative;
    /* display: inline-block; */
}

.DropDrownMenu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
    transition: 0.3s;
}

.DropDrownMenu.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20);
    transition: var(--speed) ease;
    transition: 0.5s;
}

.sidebaricon {
    position: relative;
    border-radius: 4px;
    /* border: 1px solid #fbfbfb; */
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
}

.sidebaricon:hover {
    cursor: pointer;
    border-radius: 4px;
    color: #5c5c5c;
    /* border: 1px solid #fbfbfb; */
    padding: 8px;
}

.sidebaricon:active {
    cursor: pointer;
    border-radius: 4px;
    color: #4FBC9C;
    /* border: 1px solid #fbfbfb; */
    padding: 8px;
}

.leftelements {
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    text-align: center;
    font-size: 18px;
    color: #909090;
    font-family: Poppins;
}

.logo {
    width: 148px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 12px 0px 0px;
}

.rightelements {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 36px;
    }

.header {
    margin: 0 !important;
    height: 42px;
    display: flex;
    align-items: center;
    color: #909090;
    position: sticky;
    z-index: 100;
    padding: 16px;
    flex-direction: row;
    justify-content: space-between;
    /* box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1); */
}

.headerNav {
    display: flex;
    flex: 1;    
    justify-content: flex-end;
    align-items: center;
    gap: 32px;
    padding: 0px 0px 0px 30px;
}

.iconSize {
    width: 32px;
    height: 32px;
    scale: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4FBC9C;
}

.SearchSize {
    color: #B3B3B3;
}

.searchBar {
    position: relative;
    border-radius: 24px;
    background-color: rgba(251, 251, 251, 0.48);
    border: 1px solid #b3b3b3;
    box-sizing: border-box;
    width: 452px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 48px 12px 12px;
    gap: 12px;
    text-align: center;
    font-size: 18px;
    color: #909090;
    font-family: Poppins;
    }

.Search {
    display: flex;
}
form {
    display: flex;
    flex: 1;
}

form > input {
    display: flex;
    flex: 1;
    background-color: transparent;
    border: none;
    color: var(--Color-6, #303030);
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    padding: 0px 12px;
}

form > button {
    display: none;
}

form > input:focus {
    outline: none;
}

.ProfilePicIcon {
    width: 36px;
    background-color: #028f8d;
    border: 1px solid var(--White, #FFF);
    border-radius: 24px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.105);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.ProfilePicIcon:hover {
    cursor: pointer;
    border: 1px solid var(--White, #011111);
    transition: 0.3s;
}

.ProfilePicIcon:active {
    cursor: pointer;
    border: 1px solid var(--White, #073535);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.753);
    transition: 0.3s;
}

.ProfileIcon {
    scale: 2;
    color: #106967;
    border: 1px solid var(--White, #FFF);
    border-radius: 24px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProfileIcon:hover {
    cursor: pointer;
}

.iconSize:hover {
    cursor: pointer;
}

.help:hover {
    cursor: pointer;
}