
.EmptyCard {
    display: flex;
    /* height: 100%; */
    width: 100%;
    padding: 12px 12px 100px 12px;
    font-family: Poppins;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
    background: linear-gradient(94deg, #FFF -8.92%, #F8F8F8 115.1%);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.06), -2px -2px 9px 0px rgba(0, 0, 0, 0.06); 
    overflow: hidden;   
}

.Helpbar {
    display: flex;
    padding: 12px;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    align-self: stretch;
}

.iconSizeInContent {
    width: 32px;
    height: 32px;
    scale: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8E8E8E;
}

.PersonNote {
    display: flex;
    align-items: center;
}

.Heading2 {
    color: var(--Color-2, #167D7F);
    width: 636px;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-wrap: pretty;
}

.helper {
    width: 380px;
    color: var(--Color-9, #333);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
