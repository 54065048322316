.dropContainer {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #515151;
    font-family: Poppins;
}

.labelName {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8px;
    box-sizing: border-box;
    text-align: left;
    font-size: 18px;
    color: #515151;
    font-family: Poppins;
}

.Label {
    color: #515151;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.reqText {
    position: relative;
    font-weight: 300;
}

.asterisk {
    position: relative;
    font-size: 18px;
    font-weight: 300;
    color: #c40000;
}

.Required {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    text-align: center;
    font-size: 10px;
    color: #515151;
    font-family: Poppins;
}

.DropSelect {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 8px;
    background-color: #fbfbfb00;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
}

.DropSelector {
    width: 100%;
    position: relative;
    border: none;
    appearance: none;
    border-radius: 8px;
    background-color: #fbfbfb;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
}

.DropSelector:hover {
    background-color: #98d7c2;
    transition: 0.3s;
}

.DropSelect:hover {
    cursor: pointer;
    transition: 0.3s;
}

.DropSelector:active {
    background-color: #4fbc9c;
    border: none; 
    /* transition: 0.3s; */
}

.ItemSelector {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 86px;
    border-radius: 8px;
    background-color: #fbfbfb;
    height: auto;
    display: contents;
    padding: 12px;
    text-align: center;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06), -2px -2px 9px rgba(0, 0, 0, 0.06);
}

.ItemList {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 8px;
    
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #333;
    background-color: #fbfbfb;
    font-family: Poppins;
}

.ItemList:hover {
    cursor: pointer;
    background-color: #98d7c2;
    transition: 0.3s;
}

.ItemList:focus {
    border: 2px solid #98d7c2;
    transition: 0.3s;
}

.single-drop-helper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 8px;
    box-sizing: border-box;
    text-align: left;
    font-size: 12px;
    color: #515151;
    font-family: Poppins;
}


.DropSelectErr {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 8px;
    background-color: #fbfbfb00;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
}

.DropSelectErr:hover {
    cursor: pointer;
    transition: 0.3s;
}

.DropSelectorErr {
    width: 100%;
    position: relative;
    appearance: none;
    border-radius: 8px;
    border: 1px solid var(--Error-100, #C40000);
    background: var(--Error-20, #F9E5E5);
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
}

.DropSelectorErr:hover {
    background: var(--Error-20, #ffbcbc);
    transition: 0.3s;
}

.DropSelectorErr:active {
    border: 2px solid var(--Error-100, #C40000);
}

.ItemSelectorErr {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 86px;
    z-index: 999;
    border-radius: 8px;
    border: 1px solid var(--Error-100, #C40000);
    background: var(--Error-20, #F9E5E5);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    gap: 12px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
}

.ItemListErr {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 8px;   
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #333;
    background-color: #fbfbfb00;
    font-family: Poppins;
}

.ItemListErr:hover {
    cursor: pointer;
    background: var(--Error-20, #ffbcbc);
    transition: 0.3s;
}

.ItemListErr:active {
    border: 2px solid #C40000;
}

.helperErr {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    box-sizing: border-box;
    text-align: left;
    font-size: 12px;
    color: #ffffff;
    border-radius: 8px;
    background: var(--Error-100, #C40000);
    font-family: Poppins;
    transition: 0.3s;
}

.DropSelectDisable {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 8px;
    background-color: #fbfbfb00;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #8e8e8e;
    font-family: Poppins;
}

.DropSelectorDisable {
    width: 100%;
    position: relative;
    appearance: none;
    border-radius: 8px;
    border: 1px solid var(--Disable-Border, #7D7D7D);
    background: var(--Disable-Color, #EBEBEB);
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
}

.helperDisable {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    box-sizing: border-box;
    text-align: left;
    font-size: 12px;
    color: #ffffff;
    border-radius: 8px;
    background: var(--Error-100, #8e8e8e);
    font-family: Poppins;
    transition: 0.3s;
}