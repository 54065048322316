.country-dropContainer {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #515151;
    font-family: Poppins;
}

.country-ItemSelector {
    width: 100%;
    min-width: 300px; /* Ensure a minimum width of 300px */
    position: absolute;
    left: 0px;
    bottom: 52px; /* Position the dropdown upwards */
    border-radius: 8px;
    background-color: #fbfbfb;
    height: 200px; /* Set height to 150px */
    gap: 12px;
    overflow-y: auto; /* Enable vertical scrolling */
    display: flex;
    flex-direction: column;
    padding: 12px;
    text-align: center;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06), -2px -2px 9px rgba(0, 0, 0, 0.06);
    box-sizing: border-box; /* Ensures padding is included in width */
}

.country-ItemList {
    width: 100%;
    /* min-width: 300px; */
    position: relative;
    border: none;
    border-radius: 8px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    color: #333;
    background-color: #fbfbfb;
    font-family: Poppins;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis to overflowing text */
}

.country-ItemList:hover {
    background-color: #4fbc9c;
    color: white;
}

.CountryDropSelect {
    position: relative;
    border: none;
    border-radius: 8px;
    background-color: #fbfbfb00;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
    gap: 12px;
    max-width: 200px; /* Ensure a maximum width of 200px */
}

.phonecountrycode {
    position: relative;
    border-radius: 8px 0px 0px 8px;
    background-color: #e0e0e0;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    box-sizing: border-box;
    text-align: center;
    font-size: 20px;
    color: #333;
    font-family: Poppins;
    width: auto; /* Ensure it adapts to its content without a fixed width */
    max-width: 200px; /* Ensure a maximum width of 200px */
}

.code-input {
    display: flex;
    width: 100%;
}
