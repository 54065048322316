.body {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    text-align: left;
    font-size: 16px;
    color: #007c89;
    font-family: Poppins;
}
.sidebar {
    height: calc(100vh - 240px); /* Adjusting for a header of 50px height */
    position: relative;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    border-radius: 8px;
    background-color: rgba(179, 179, 179, 0.75);
    border: 2px solid #fbfbfb;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #007c89;
    font-family: Poppins;
}

@media (max-height: 600px) {
    .sidebar {
        height: auto; /* Fallback for small screens */
    }
}


.details {
    height: calc(100vh - 240px); /* Adjusting for a header of 50px height */
    flex: 1;
    width: 100%;
    position: relative;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #163235;
    font-family: Poppins, sans-serif;
    overflow-y: scroll;
}    


@media (max-height: 600px) {
    .details {
        height: auto; /* Fallback for small screens */
    }
}