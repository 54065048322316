.userdrop {
  position: absolute;
  margin: 0 !important;
  width: 301px;
  top: 52px;
  left: -255px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  border-radius: 10px;
  background-color: rgba(244, 244, 244, 0.86);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px;
  box-sizing: border-box;
  gap: 24px;
  font-family: Poppins;
  z-index: 999;
  }

  .userdrop::before {
    content: '';
    position: absolute;
    top: -4px;
    left: 270px;
    right: 20px;
    height: 20px;
    width: 20px;
    background-color: rgba(244, 244, 244, 0.86);
    transform: rotate(45deg);
  }

  .rectangleDiv {
    width: 100%;
    position: relative;
    border-radius: 12px;
    background-color: #4fbc9c;
    height: 2px;
    }

  .ProfilePic {
  	width: 84px;
  	position: relative;
  	border-radius: 50%;
  	object-fit: cover;
  	z-index: 0;
}

.EditIcon {
  width: 25px;
  position: absolute;
  left: 74px;
  top: 74px;
  z-index: 40;
  background: rgb(255, 255, 255);
  border-radius: 50px;
}
.edit {
  	margin: 0 !important;
  	position: absolute;
  	top: 80px;
  	left: 71px;
  	border-radius: 30px;
  	background-color: #fff;
  	border: 2px solid #4fbc9c;
  	display: flex;
  	flex-direction: row;
  	align-items: flex-start;
  	justify-content: flex-start;
  	padding: 8px;
  	z-index: 1;
}
.profilepic {
  	display: flex;
  	flex-direction: row;
  	align-items: flex-start;
  	justify-content: flex-start;
  	position: relative;
  	gap: 4px;
}
.UserName {
  position: relative;
  font-size: 18px;
  font-family: Poppins;
  color: #163235;
  text-align: left;
  background-color: #fff;
}
.UserDesignation {
  position: relative;
  font-size: 14px;
  font-family: Poppins;
  color: #4fbc9c;
  text-align: left;
  background-color: #fff;
}

.NameSection {
  background-color: #fff;
  	display: flex;
  	flex-direction: column;
  	align-items: flex-start;
  	justify-content: flex-start;
  	gap: 2px;
}

.nameCard {
  position: relative;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  box-sizing: border-box;
  gap: 24px;
  text-align: left;
  font-size: 24px;
  color: #163235;
  font-family: Poppins;
}

.manageSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #fbfbfb;
}