.Footer {
    display: flex;
    padding: 12px 28px;
    align-items: center;
    gap: 4px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    color: #163235;
    text-align: center;
    font-family: Poppins, sans-serif;
}