.GenderAge {
    color: var(--Teal-dark, #08191C);
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
}

.Severity {
    width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    text-align: center;
    font-size: 11px;
    color: #fbfbfb;
    }

.patient-name {
    align-self: stretch;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06), -2px -2px 9px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    background: linear-gradient(93.84deg, #fff, #f8f8f8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    gap: 4px;
    text-align: left;
    }

.line1 {
    align-self: stretch;
    position: relative;
    font-weight: 600;
}
.line2 {
position: relative;
color: #08191c;
font-size: 10px;
}

.documents {
    align-self: stretch;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06), -2px -2px 9px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    background: linear-gradient(93.84deg, #f6f6f6, #e6e6e6 68.9%, #f8f8f8);
    border: 2px solid #fbfbfb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    gap: 4px;
    font-size: 12px;
    color: #163235;
    }

.button-section {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    color: #fbfbfb;
    }

.patient-card {
    width: 248px;
    position: relative;
    backdrop-filter: blur(12px);
    border-radius: 8px;
    background-color: rgba(252, 252, 252, 0.75);
    border: 2px solid #fbfbfb;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    gap: 8px;
    text-align: center;
    font-size: 16px;
    color: #007c89;
    font-family: Poppins;
    }

.patient-card:hover {
    border: 2px solid #fbfbfb;
    box-shadow: inset 0px 0px 6px 1px rgba(0, 0, 0, 0.350);
    transition: 0.3s;
}

.ActualName {
    overflow: hidden;
    color: var(--Information-100, #007C89);
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    }

.patient-name {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    background: linear-gradient(94deg, #FFF -8.92%, #F8F8F8 115.1%);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.06), -2px -2px 9px 0px rgba(0, 0, 0, 0.06);        
    }

.caseName {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    align-self: stretch;  
    border-radius: 8px;
    background: linear-gradient(94deg, #FFF -8.92%, #F8F8F8 115.1%);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.06), -2px -2px 9px 0px rgba(0, 0, 0, 0.06);    
}

.patientName {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Information-100, #007C89);
    text-overflow: ellipsis;
    
    /* Label */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;        
}

.status {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
    color: #fbfbfb;
}

.BtnWidthDef {
    display: inline-block;
    width: 100%;
}

