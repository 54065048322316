.labelName {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8px;
    box-sizing: border-box;
    text-align: left;
    font-size: 18px;
    color: #515151;
    font-family: Poppins;
}

.Label {
    color: #515151;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.reqText {
    position: relative;
    font-weight: 300;
}

.asterisk {
    position: relative;
    font-size: 18px;
    font-weight: 300;
    color: #c40000;
}

.Required {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    text-align: center;
    font-size: 10px;
    color: #515151;
    font-family: Poppins;
}

.InputLine {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 8px;
    background-color: #fbfbfb;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
}

.InputLine:hover {
    border-radius: 8px;
    border: 1px solid #4fbc9c;
}

.InputLine:focus {
    outline: none;
    border-radius: 8px;
    border-left: 4px solid #4fbc9c;
    box-sizing: border-box;
}

.InputLineErr {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 8px;
    background-color: #f9e5e5;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
}

.InputLineErr:hover {
    border-radius: 8px;
    border: 1px solid #C40000;    
}

.InputLineErr:focus {
    outline: none;
    border-radius: 8px;
    border-left: 4px solid #C40000;
    box-sizing: border-box;
}

.InputContainer {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #515151;
    font-family: Poppins;
}

.InputLineContainer {
    width: 100%;
    position: relative;
    border: none;
    appearance: none;
    border-radius: 8px;
    background-color: #fbfbfb;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
}

.inputIcon {
    position: absolute;
    right: 12px;
    display: flex;
    align-items: center;
    color: gray;
}

.helper {
    font-size: 12px;
    color: #666;
    margin-top: 5px;
}

.helperErr {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    box-sizing: border-box;
    text-align: left;
    font-size: 12px;
    color: #ffffff;
    border-radius: 8px;
    background: var(--Error-100, #C40000);
    font-family: Poppins;
    transition: 0.3s;
}

.InputLineDisable {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 8px;
    border: 1px solid var(--Disable-Border, #7D7D7D);
    background: var(--Disable-Color, #EBEBEB);
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    color: #8e8e8e;
    font-family: Poppins;
}