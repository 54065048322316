.minValue {
    width: 20%;
}

.maxValue {
    width: 100%;
}
    
    .container {
        height: 92%;
        position: relative;
        backdrop-filter: blur(12px);
        border-radius: 8px;
        background-color: #eeeeee;
        border: 2px solid #ccc;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 12px;
        text-align: center;
        font-size: 24px;
        color: #333;
        font-family: Poppins;
        /* box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1); */
    }
        
    .cases {
        position: relative;
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.18));
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
        text-align: center;
        font-size: 1px;
        color: #007c89;
        font-family: Poppins;
        overflow-y: scroll;
    }
    
    .grid-content {
        width: 100%;
        height: calc(100vh - 190px);
        display: flex;
        flex-direction: row;
        gap: 12px;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
    }
    
    .main-head {
        width: 100%;
        position: relative;
        box-shadow: 4px 4px 32px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        background: linear-gradient(-17.07deg, #e6e6e6, #f3f3f3 51.26%, #eaeaea);
        border: 3px solid #fff;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        margin: 0px 0px 12px 0px;
        text-align: left;
        font-size: 16px;
        color: #163235;
        font-family: Poppins;
        }
    
    .title {
        flex: 1;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        color: #163235;
        font-family: Poppins;
    }
    