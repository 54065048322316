.loading {
    font-family: Poppins;
}

.activeButton {
    display: none;
}

.loader {
    display: flex;
    justify-content: center;
    font-family: Poppins;
    font-size: 24px;
    overflow: hidden;
}

.togglespace {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.fontName {
    font-family: Poppins;
}
.sortHover {
    border-radius: 4px;
    border: 2px solid #fbfbfb00;
    padding: 2px;  
}

.sortHover:hover {
    background-color: #fbfbfb;
    border: 2px solid #fbfbfb;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    padding: 2px;
    transition: 0.3s;
}

.sortHover:active {
    background-color: #fbfbfb;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.18);
    border: 2px solid #4FBC9C;
    border-radius: 4px;
    padding: 2px;
    transition: 0.3s;
}
.Tooltip {
    font-family: Poppins;
    font-size: 24px;
}
.case-name {
    font-size: 24px;
    font-weight: 600;
    font-family: Poppins;
}
.titlebar {
    flex: 1;
    height: 60px;
    position: relative;
    backdrop-filter: blur(12px);
    border-radius: 8px;
    background-color: #eeeeee;
    border: 2px solid #ccc;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    text-align: center;
    font-size: 24px;
    color: #333;
    font-family: Poppins;
    width: 100%;
    /* box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1); */
}

.Title {
    position: relative;
    display: flex;
    font-size: 24px;
    font-weight: 600;
    font-family: Poppins;
    color: #333;
    text-align: center;
    align-items: center;
    flex-direction: row;
    gap: 16px;

}

.caseHead {
    width: 100%;
    position: relative;
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
}

.actions {
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    text-align: left;
    font-size: 14px;
    color: #6e6e72;
    font-family: Poppins;
}

.toggleGRP {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 28px;
    text-align: left;
    font-size: 14px;
    color: #6e6e72;
    font-family: Poppins;
}
.actionGRP {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    text-align: left;
    font-size: 14px;
    color: #6e6e72;
    font-family: Poppins;
}