.loadingFeedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-left-color: #4A90E2;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loadingMessage {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

/* _____________________ */

.forgotPassword {
    position: relative;
    font-size: 16px;
    font-family: Poppins;
    color: #4fbc9c;
    text-align: left;
}

.forgotPassword:hover {
    cursor: pointer;
    color: #106967;
    transition: 0.3s;
}

.forgotAndKeepMeSignIn {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #106967;
    font-family: Poppins;
}

.LoginTitle {
    position: relative;
    font-size: 46px;
    letter-spacing: -0.06em;
    font-family: Poppins;
    color: #106967;
    text-align: center;
    font-weight: bold;
}

.loginSection {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    text-align: left;
    font-size: 18px;
    color: #515151;
    font-family: Poppins;
}

.loginCard {
    position: relative;
    box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    background: linear-gradient(137.29deg, #f7f7f7, #b7b7b7);
    border: 2px solid #fbfbfb;
    box-sizing: border-box;
    width: 464px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px;
    gap: 40px;
    text-align: center;
    font-size: 18px;
    color: #106967;
    font-family: Poppins;
}

.headerAndContent {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    text-align: center;
    font-size: 24px;
    color: #163235;
    font-family: Poppins;
}

.LoginContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px 28px;
    box-sizing: border-box;
    gap: 180px;
    text-align: center;
    font-size: 24px;
    color: #163235;
    font-family: Poppins;
}

.logoSection {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 42px;
    text-align: center;
    font-size: 24px;
    color: #163235;
    font-family: Poppins;
}

.slogan {
    width: 314px;
    position: relative;
    font-size: 24px;
    font-family: Poppins;
    color: #163235;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lineDiv {
    width: 100%;
    position: relative;
    border-top: 5px solid #32bfda;
    box-sizing: border-box;
    height: 5px;
}

.logo {
    width: 250px;
}

::-webkit-scrollbar {
    width: .5rem;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px white;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
}