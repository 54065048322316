/* DateInput.css */

.date-input-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #515151;
    font-family: Poppins;
}

.date-label {
    color: #515151;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.date-input-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.date-input {
    width: 100%;
    border: none;
    border-radius: 8px;
    background-color: #fbfbfb;
    height: 48px;
    padding: 12px;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    color: #333;
    font-family: Poppins;
    padding-right: 40px; /* Adjust for icon spacing */
    /* Hide the default calendar icon */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.date-input::-webkit-calendar-picker-indicator {
    display: none; /* Hide the default calendar icon in WebKit browsers */
}

.date-input:hover {
    border-radius: 8px;
    border: 1px solid #4fbc9c;
}

.date-input:focus {
    outline: none;
    border-radius: 8px;
    border-left: 4px solid #4fbc9c;
    box-sizing: border-box;
}

.calendar-icon {
    position: absolute;
    right: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
}

.calendar-icon svg {
    fill: #333;
}
